import { CookiePopup, Footer } from 'components';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { useCookies } from 'react-cookie';
import { buttons, page, spacing, titles, utils } from 'style';

const CookieSettingsPage: React.FC = () => {
  const intl = useIntl();

  const [cookies, setCookie] = useCookies([
    'nike-bra-analytics-cookie-consent',
  ]);

  return (
    <>
      <div css={utils.wrapper}>
        <article css={[page.root]}>
          <header css={page.header}>
            <h1 css={titles.primary}>
              <FormattedMessage id="cookieSettingsTitlePartOne" />
              <br />
              <FormattedMessage id="cookieSettingsTitlePartTwo" /> <br />
              <FormattedMessage id="cookieSettingsTitlePartThree" />
            </h1>
          </header>
          <div css={[page.content, utils.formatted]}>
            <div css={spacing.top.large}>
              <div css={[utils.switchComponent, spacing.bottom.xlarge]}>
                <input
                  checked={true}
                  type="checkbox"
                  name="functional"
                  id="functional"
                  css={utils.switchInput}
                />
                <label css={utils.switchLabel} htmlFor="functional">
                  <FormattedMessage id="functionalCookiesLabel" />
                </label>
              </div>
              <p css={[utils.typeBase, utils.fontFamilySansAlt]}>
                <FormattedMessage id="functionalCookiesDescription" />
              </p>
            </div>
            <div css={spacing.top.large}>
              <div css={[utils.switchComponent, spacing.bottom.xlarge]}>
                <input
                  onChange={handleCheckboxToggle}
                  checked={cookies['nike-bra-analytics-cookie-consent']}
                  id="analytics"
                  name="analytics"
                  type="checkbox"
                  css={utils.switchInput}
                />
                <label css={utils.switchLabel} htmlFor="analytics">
                  <FormattedMessage id="analyticsCookieTitle" />
                </label>
              </div>
              <p css={[utils.typeBase, utils.fontFamilySansAlt]}>
                <FormattedMessage id="analyticsCookieDescription" />
              </p>
              <Link
                to="/"
                css={[buttons.base, buttons.primary, spacing.top.xlarge]}
              >
                <FormattedMessage id="cookieSettingsDoneCta" />
              </Link>
            </div>

            <div css={spacing.top.large}>
              <p css={[utils.typeBase, utils.fontFamilySansAlt]}>
                <FormattedMessage
                  id="cookieSettingsDetails"
                  values={{
                    link: (
                      <a
                        target="_blank noopener noreferrer"
                        css={[utils.typeUnderline]}
                        href={intl.formatMessage({ id: 'privacyPolicyLink' })}
                      >
                        <FormattedMessage id="privacyAndCookiePolicy" />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <CookiePopup />
        </article>
      </div>
      <Footer />
    </>
  );

  function handleCheckboxToggle(event: React.ChangeEvent<HTMLInputElement>) {
    const checked = event.currentTarget.checked;

    if (checked) {
      allowCookies();
    } else {
      declineCookies();
    }
  }

  function allowCookies() {
    setCookie('nike-bra-analytics-cookie-consent', true);
  }

  function declineCookies() {
    setCookie('nike-bra-analytics-cookie-consent', false);
  }
};

export default CookieSettingsPage;
